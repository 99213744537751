import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import { addGuide } from "../../actions";
import { Link } from "gatsby";
import EditGuide from "../../collections/EditGuide";
import {
  PageHeader,
  LinkButton,
  Dropdown,
  ActionPanel,
  SectionHeading,
} from "../../common";
import ContactEdit from "./guideForms/ContactEdit";
import PolicyEdit from "./guideForms/PolicyEdit";
import triage_data from "../../data/triage.json";

const Index = () => {
  const dispatch = useDispatch();

  const [title, update_title] = useState("");
  const [text, update_text] = useState("");
  const [type, update_type] = useState("Triage");
  const [data1, update_data1] = useState("");
  const [triage, update_triage] = useState(triage_data[0]);
  const [triage_prompt, update_triage_prompt] = useState(null);
  const [response, update_response] = useState(null);
  function handleChangeText(data) {
    console.log(text);
    update_text(data);
  }

  function handleSave() {
    console.log("saving...");

    const data = {
      title: title,
      type: type,
      text: text,
      data: JSON.stringify(triage),
      data2: triage_prompt,
      data3: response,
    };

    dispatch(addGuide(data));
    navigate("/guides/browse");
  }
  function handleChangeTriagePrompt(text) {
    update_triage_prompt(text);
  }
  function updateTriage(data) {
    console.log("updating triage");
    console.log(data);
    update_triage(data);
  }

  function returnEdit() {
    switch (type) {
      case "Triage":
        return (
          <div className="paddingTop paddingBottom">
            <EditGuide
              title={title}
              text={text}
              update_title={update_title}
              handleChangeText={handleChangeText}
              handleSave={handleSave}
              handleChangeTriagePrompt={handleChangeTriagePrompt}
              update_response={update_response}
              triage_prompt={triage_prompt}
              response={response}
              updateTriage={updateTriage}
            />
          </div>
        );
        break;
      case "Contact":
        return (
          <div className="paddingTop paddingBottom">
            <ContactEdit
              title={title}
              text={text}
              update_title={update_title}
              handleChangeText={handleChangeText}
              handleSave={handleSave}
            />
          </div>
        );
        break;
      case "Policy":
        return (
          <div className="paddingTop paddingBottom">
            <PolicyEdit
              title={title}
              text={text}
              update_title={update_title}
              handleChangeText={handleChangeText}
              handleSave={handleSave}
            />
          </div>
        );
      default:
        return <div>asd</div>;
        break;
    }
  }

  function returnType() {
    const array = ["Triage", "Contact", "Policy"];
    function indexClicked(index) {
      console.log(index);
      update_type(array[index]);
    }

    return (
      <div>
        <Dropdown array={array} indexClicked={indexClicked} title={type} />
      </div>
    );
  }

  return (
    <>
      <PageHeader title="New Guide">
        <LinkButton
          to="/guides/browse/"
          type="secondary"
          title="Back to guides"
        />
      </PageHeader>
      <ActionPanel
        title="Select Guide Type"
        message="Once you have selected your guide type you can add the information"
        action={returnType()}
      />

      {returnEdit()}
    </>
  );
};

export default Index;
