import React from "react";
import { SectionHeading, FormContainer } from "../../../common";
import TextEditor from "../../../pages/guides/TextEditor";
const PolicyEdit = ({
  title,
  text,
  update_title,
  handleChangeText,
  handleSave,
}) => {
  function returnForm() {
    return (
      <div>
        <SectionHeading
          title="New Policy"
          message="Enter the policy informtaion below."
        />
        <label
          style={{ textAlign: "left" }}
          className="block text-sm font-medium text-gray-700 paddingTop"
        >
          Title
        </label>
        <input
          label={"label"}
          className="paddingTop appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          name="title"
          type="text"
          value={title}
          onChange={(e) => update_title(e.target.value)}
        />
        <div className="paddingTop">
          <label
            style={{ textAlign: "left" }}
            className="block text-sm font-medium text-gray-700 paddingBottom5"
          >
            Policy
          </label>
          <TextEditor text={text} handleChangeText={handleChangeText} />
        </div>
      </div>
    );
  }

  return <FormContainer data={returnForm()} submitSave={handleSave} />;
};

export default PolicyEdit;
