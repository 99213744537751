import React from "react";
import { FormContainer, SectionHeading } from "../../../common";

const ContactEdit = ({
  title,
  text,
  update_title,
  handleChangeText,
  handleSave,
}) => {
  function returnForm() {
    return (
      <div>
        <>
          <SectionHeading
            title="New Contact Guide"
            message="Enter the contact informtaion below."
          />
          <label
            style={{ textAlign: "left" }}
            className="block text-sm font-medium text-gray-700 paddingTop"
          >
            Title
          </label>
          <input
            label={"label"}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            name="title"
            type="text"
            value={title}
            onChange={(e) => update_title(e.target.value)}
          />

          <label
            style={{ textAlign: "left" }}
            className="block text-sm font-medium text-gray-700 paddingTop"
          >
            Contact
          </label>
          <input
            label={"label"}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            name="title"
            type="text"
            value={text}
            onChange={(e) => handleChangeText(e.target.value)}
          />
        </>
      </div>
    );
  }
  return <FormContainer data={returnForm()} submitSave={handleSave} />;
};

export default ContactEdit;
