import React from "react";
import Layout from "../../components/layout";
import Container from "../../common";
import NewGuide from "../../components/guides/new";

const Index = () => {
  return (
    <Layout>
      <Container>
        <NewGuide />
      </Container>
    </Layout>
  );
};

export default Index;
